<img
  [ngSrc]="name | illustrationSrc : assetPath : fileType"
  [alt]="name | illustrationAlt"
  [width]="size?.width"
  [height]="size?.height"
  [attr.class]="classes"
  [style.width.px]="!classes ? size?.width : null"
  [style.height.px]="!classes ? size?.height : null"
  [style.display]="!classes ? 'block' : null"
  [priority]="priority"
  [loading]="loading"
  role="img"
/>
