import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Optional,
  Output,
  Renderer2,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import '@angular/localize/init';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { getDynamicId } from '@backbase/ui-ang/util';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  COUNTRY_CODE_FORMAT_CONFIG_TOKEN,
  CountryCodeFormatConfig,
  CountryCodeFormatConfigMap,
  INPUT_PHONE_CONFIG_TOKEN,
  InputPhoneConfig,
} from './input-phone.configuration_token';
import { extractCountryFromValue, stripCountryCodeFromValue } from './phone-number-validation-helper';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

/**
 * @name InputPhoneComponent
 *
 * @description
 * Component that enables the user to enter a phone number.
 *
 * ### Global configuration token
 * `INPUT_PHONE_CONFIG_TOKEN` enables you to globally set the same configuration for all instances of `InputPhoneComponent` in your project.
 *
 *
 * The following properties can be overwritten using the token:
 *  - `maxLength`
 *  - `minLength`
 *  - `autocomplete`
 *  - `mask`
 *  - `displayFormat`
 *  - `validationPattern`
 *  - `hideSelectedCountryFlag`
 *  - `defaultCountryIsoCode`
 *  - `countryList`
 *
 * #### Usage notes
 * The following is an example of how to use the token:
 *
 * ```
 *  import { INPUT_PHONE_CONFIG_TOKEN } from '@backbase/ui-ang/input-phone';
 *  import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *  import { AppModule } from './app/app.module';
 *
 *  const inputPhoneConfig = {
 *    maxLength: 10,
 *    minLength: 5,
 *    autocomplete: 'off',
 *    displayFormat: 'E.164',
 *  }
 *
 *  providers: [
 *    {
 *      provide: INPUT_PHONE_CONFIG_TOKEN,
 *      useValue: inputPhoneConfig,
 *    },
 *  ]
 * ```
 *
 * ### Country Code Format Config Token
 * `COUNTRY_CODE_FORMAT_CONFIG_TOKEN` enables you to globally set the same configuration for all instances of `InputPhoneComponent` in your project.
 *
 * #### Usage notes
 * The following is an example of how to use the token.
 * Note: the `minLength` and `maxLength` properties do not account for the country dialling code:
 *
 * ```
 *  import { COUNTRY_CODE_FORMAT_CONFIG_TOKEN } from '@backbase/ui-ang/input-phone';
 *  import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *  import { AppModule } from './app/app.module';
 *
 *  const countryCodeFormatConfig = {
 *    '+1': {
 *      mask: '+0 (000) 000-0000',
 *      minLength: 8,
 *      maxLength, 12,
 *    },
 *  };
 *
 *  providers: [
 *    {
 *      provide: COUNTRY_CODE_FORMAT_CONFIG_TOKEN,
 *      useValue: countryCodeFormatConfig,
 *    },
 *  ]
 * ```
 *
 * IMPORTANT NOTE:
 *  - If you wish to validate & format ALL phone numbers using the SAME `minLength`, `maxLength` and/or `mask`, please configure these values using the INPUT_PHONE_CONFIG_TOKEN.
 *  - If you need to support phone numbers for multiple countries (DIFFERENT `minLength`, `maxLength` & `mask`), we advise to use the COUNTRY_CODE_FORMAT_CONFIG_TOKEN instead.
 *
 *  @a11y Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - role
 *  - aria-activedescendant
 *  - aria-describedby
 *  - aria-expanded
 *  - aria-invalid
 *  - aria-label
 *  - aria-labelledby
 *  - aria-owns
 *
 * ariaLabel is discernible text for phone input
 */

export interface CountryData {
  countryCode: string;
  isoCode: string;
  countryName: string;
}
export type CountryList = Array<CountryData>;
export interface ValueSet extends CountryData {
  number: string;
}

@Component({
  selector: 'bb-input-phone-ui',
  templateUrl: './input-phone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPhoneComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputPhoneComponent),
      multi: true,
    },
  ],
})
export class InputPhoneComponent extends InputBaseComponent implements AfterViewInit, Validator, OnDestroy {
  override writeValue(value: any): void {
    if (value && typeof value === 'object') {
      const selectedCountry = this.countryList?.find((country) => country.isoCode === value.isoCode);

      this.selectedCountryCode = value.countryCode;
      this.selectedCountryIsoCode = value.isoCode;
      this.selectedCountryName = selectedCountry?.countryName;
      this.value = value.number;
      this.valueWithoutCountryCode = value.number;
      this.updateValues(true);
    } else {
      this.value = value;
    }
  }

  /**
   * The max size of the phone input.
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input('maxLength')
  get maxLength(): InputPhoneConfig['maxLength'] {
    return this._maxLength ?? this.overrideConfiguration?.maxLength;
  }

  set maxLength(value: InputPhoneConfig['maxLength']) {
    this._maxLength = value;
  }
  private _maxLength: InputPhoneConfig['maxLength'];

  /**
   * The validation pattern of the phone input.
   * This attribute can be overwritten via the global configuration token.
   */
  @Input('validationPattern')
  get validationPattern(): InputPhoneConfig['validationPattern'] {
    return this._validationPattern ?? this.overrideConfiguration?.validationPattern;
  }

  set validationPattern(value: InputPhoneConfig['validationPattern']) {
    this._validationPattern = value;
  }
  private _validationPattern: InputPhoneConfig['validationPattern'];

  /**
   * The size of the phone input.
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input('minLength')
  get minLength(): InputPhoneConfig['minLength'] {
    return this._minLength ?? this.overrideConfiguration?.minLength;
  }
  set minLength(value: InputPhoneConfig['minLength']) {
    this._minLength = value;
  }
  private _minLength: InputPhoneConfig['minLength'];
  /**
   * The autocomplete value of the enclosed input control. Defaults to 'tel'
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input('autocomplete')
  get autocomplete(): InputPhoneConfig['autocomplete'] {
    return this._autocomplete ?? this.overrideConfiguration?.autocomplete;
  }
  set autocomplete(value: InputPhoneConfig['autocomplete']) {
    this._autocomplete = value;
  }
  private _autocomplete: InputPhoneConfig['autocomplete'];

  /**
   * The mask of the phone input. Defaults to null
   * This attribute can be overwritten via the global configuration token
   */
  @Input('mask')
  get mask(): InputPhoneConfig['mask'] {
    return this._mask ?? this.overrideConfiguration?.mask;
  }
  set mask(value: InputPhoneConfig['mask']) {
    this._mask = value;
  }
  private _mask: InputPhoneConfig['mask'];

  /**
   * Whether to hide the flag of the selected country. Defaults to false
   * This attribute can be overwritten via the global configuration token
   */
  @Input('hideSelectedCountryFlag')
  get hideSelectedCountryFlag(): InputPhoneConfig['hideSelectedCountryFlag'] {
    return this._hideSelectedCountryFlag ?? this.overrideConfiguration?.hideSelectedCountryFlag ?? false;
  }
  set hideSelectedCountryFlag(value: InputPhoneConfig['hideSelectedCountryFlag']) {
    this._hideSelectedCountryFlag = value;
  }
  private _hideSelectedCountryFlag: InputPhoneConfig['hideSelectedCountryFlag'];

  /**
   * The placeholder for the phone input. Defaults to an empty string;
   */
  @Input() placeholder = '';
  /**
   * Whether the phone input is readonly.
   */
  @Input() override readonly = false;
  /**
   * ariaLabel is discernible text for phone input.
   */
  @Input() override ariaLabel = 'Phone Input';
  /**
   * Dropdown Position (bottom | top | auto)
   */
  @Input() dropdownPosition: 'bottom' | 'top' | 'auto' | undefined;
  /**
   * Allow to clear selected value.
   */
  @Input() clearable = false;
  /**
   * Allow to search for value.
   */
  @Input() searchable = false;
  /**
   * List of countries to display in dropdown.
   * This attribute can be overwritten via the global configuration token
   */
  @Input('countryList')
  get countryList(): InputPhoneConfig['countryList'] {
    return this._countryList ?? this.overrideConfiguration?.countryList;
  }
  set countryList(value: InputPhoneConfig['countryList']) {
    this._countryList = value;
  }
  private _countryList: InputPhoneConfig['countryList'];
  /**
   * Default country selected for country dropdown.
   * This attribute can be overwritten via the global configuration token
   */
  @Input('defaultCountryIsoCode')
  get defaultCountryIsoCode(): InputPhoneConfig['defaultCountryIsoCode'] {
    return this._defaultCountryIsoCode ?? this.overrideConfiguration?.defaultCountryIsoCode;
  }
  set defaultCountryIsoCode(value: InputPhoneConfig['defaultCountryIsoCode']) {
    this._defaultCountryIsoCode = value;
    this.selectedCountryIsoCode = value;
  }
  private _defaultCountryIsoCode: InputPhoneConfig['defaultCountryIsoCode'];

  /**
   * Enable/Disable Country code dropdown
   * This attribute can be overwritten via the global configuration token
   */
  @Input('enableCountryCode')
  get enableCountryCode(): InputPhoneConfig['enableCountryCode'] {
    return this._enableCountryCode ?? this.overrideConfiguration?.enableCountryCode;
  }
  set enableCountryCode(value: InputPhoneConfig['enableCountryCode']) {
    this._enableCountryCode = value;
  }
  private _enableCountryCode: InputPhoneConfig['enableCountryCode'];

  /**
   * Allows manual control of dropdown opening and closing. `true` - won't close. `false` - won't open.
   * Defaults to `undefined`.
   */
  @Input()
  set isOpen(value) {
    this._isOpen$.next(value);
  }

  get isOpen() {
    return this._isOpen$.getValue();
  }

  private readonly _isOpen$ = new BehaviorSubject<boolean | undefined>(undefined);

  /**
   * The event emitter called when the one of the inputs change.
   */
  @Output() valueChange = new EventEmitter<ValueSet>();

  @ViewChild('inputPhone') inputEl: ElementRef | undefined;
  /**
   * ng-select
   */
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent | undefined;

  selectedCountryCode?: string;
  selectedCountryIsoCode?: string;
  selectedCountryName?: string;
  countryDropdowndisabled?: boolean;
  override value = '';
  valueSet: ValueSet = {
    countryCode: '',
    countryName: '',
    isoCode: '',
    number: '',
  };
  resultCount = 0;
  countryCodeInputId = `country-code-input-${getDynamicId()}`;
  notFoundText = $localize`:Text no items found during search or filter@@input-phone.no-items-found:No items found`;
  private readonly _statusId = this.domAttrService.generateId();

  get statusId() {
    return this._statusId;
  }

  get useDefaultAriaDescribedby() {
    return !this.ariaDescribedby && this.countryDropdowndisabled;
  }

  private matchingCountry?: CountryData;
  private valueWithoutCountryCode = '';
  private noMatchingCountryFound = false;
  private countrySpecificValidationConfig?: CountryCodeFormatConfig;
  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly domAttrService: DomAttributesService,
    private readonly elem: ElementRef,
    private readonly renderer2: Renderer2,
    override readonly cd: ChangeDetectorRef,
    @Optional()
    @Inject(INPUT_PHONE_CONFIG_TOKEN)
    private readonly overrideConfiguration: InputPhoneConfig,
    @Optional()
    @Inject(COUNTRY_CODE_FORMAT_CONFIG_TOKEN)
    private readonly countryCodeFormatConfiguration: CountryCodeFormatConfigMap,
  ) {
    super(cd);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onTextChange() {
    this.resetMatchingCountry();
    this.findMatchingCountryCodeFromValue(this.value);
    this.selectedCountryCode = this.matchingCountry?.countryCode || this.selectedCountryCode;
    this.selectedCountryName = this.matchingCountry?.countryName || this.selectedCountryName;
    this.selectedCountryIsoCode = this.matchingCountry?.isoCode || this.selectedCountryIsoCode;

    // HACK: for cases when value is pasted in with country code(e.g. +341234), before setting in value
    // without country code we need to call change detection
    // otherwise input value wont get updated visually.
    this.cd.detectChanges();

    this.value = this.enableCountryCode ? this.valueWithoutCountryCode : this.valueWithoutCountryCode || this.value;
    this.updateValues();
  }

  updateValues(preventEmit = false): void {
    this.valueSet = {
      countryCode: this.selectedCountryCode || '',
      countryName: this.selectedCountryName || '',
      isoCode: this.selectedCountryIsoCode || '',
      number: this.value,
    };

    if (!preventEmit) {
      this.valueChange.emit(this.valueSet);
    }
  }

  ngAfterViewInit(): void {
    if (!this.ariaLabel) {
      this.domAttrService.moveAriaAttributes(
        this.elem.nativeElement,
        this.inputEl && this.inputEl.nativeElement,
        this.renderer2,
      );
    }

    this.constructCountryData();
    this.updateValues(true);
    this.removeReadonly(); // android talkback reads readonly attribute as disabled

    // force lifecycle to doCheck to prevent console ExpressionChangedAfterItHasBeenCheckedError
    this.cd.detectChanges();

    if (this.value !== undefined) {
      this.onTextChange();
    }

    this._isOpen$.pipe(takeUntil(this._destroy$)).subscribe((isOpen) => {
      if (isOpen) {
        this.moveAriaAttributes();
      }

      if (isOpen === undefined) {
        this.addAriaExpanded();
      }
    });
  }

  onChanges(event: CountryData) {
    if (!event || event.constructor !== Object) return;

    this.selectedCountryName = event.countryName;
    this.selectedCountryIsoCode = event.isoCode;
    this.selectedCountryCode = event.countryCode;
    this.countrySpecificValidationConfig =
      this.countryCodeFormatConfiguration && this.countryCodeFormatConfiguration[this.selectedCountryCode];
    this.onInputValueChange(this.value);

    this.value = this.valueWithoutCountryCode || this.value;
    this.updateValues();
  }

  /**
   * @deprecated Deprecated in ui-ang@12. To be marked as protected in ui-ang@14. No replacements.
   */
  onOpen() {
    if (!this.ngSelect?.dropdownId) {
      return;
    }
    if (this.readonly || this.disabled) {
      this.countryDropdowndisabled = true;
      this.ngSelect.isOpen = false;

      return;
    }

    // wait for DOM to be updated
    setTimeout(() => {
      this.moveAriaAttributes();
    });

    this.updateResultCount();
  }

  onClose() {
    if (this.ngSelect && !this.ngSelect.isOpen) {
      this.ngSelect.constructor.prototype.focus.call(this.ngSelect);
    }
  }

  onClear() {
    this.onFilterChange(undefined);
  }

  onFilterChange(query: string | undefined): void {
    if (this.ngSelect) {
      this.ngSelect.filter(query || '');
    }
    this.updateResultCount();
  }

  searchFunc(term: string, item: CountryData): boolean {
    term = term.toLocaleLowerCase();

    return (
      item.countryCode.toLocaleLowerCase().indexOf(term) > -1 ||
      item.countryName.toLocaleLowerCase().indexOf(term) > -1 ||
      item.isoCode.toLocaleLowerCase().indexOf(term) > -1
    );
  }

  onInputValueChange(newValue: string) {
    // remove country code from phone number before validating the input
    this.resetMatchingCountry();
    this.findMatchingCountryCodeFromValue(newValue);
    this.selectedCountryCode = this.matchingCountry?.countryCode ?? this.selectedCountryCode;
    this.selectedCountryName = this.matchingCountry?.countryName ?? this.selectedCountryName;
    this.selectedCountryIsoCode = this.matchingCountry?.isoCode ?? this.selectedCountryIsoCode;

    const countryCode = this.selectedCountryCode || '';
    if (!newValue) {
      this.onValueChange('');

      return;
    }
    this.onValueChange(`${countryCode}${this.valueWithoutCountryCode || newValue}`);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value === '') {
      return null;
    }

    if (this.isPhoneNumberEmpty()) return { required: true };

    if (this.checkValidationPattern(control)) return { invalidFormat: true };

    if (this.noMatchingCountryFound) return { countryCodeUnsupported: true };

    return this.isMinLengthInvalid(control.value) ?? this.isMaxLengthInvalid(control.value);
  }

  private isPhoneNumberEmpty() {
    if (!this.required) {
      return false;
    }

    const validateWithCountryCode = Boolean(this.enableCountryCode && this.countryList && this.valueSet.countryCode);
    const isEmptyPhoneNumber = this.valueWithoutCountryCode === '' || this.valueWithoutCountryCode === '+';

    return validateWithCountryCode && isEmptyPhoneNumber;
  }

  private isMinLengthInvalid(value?: string) {
    const minLength = this.countrySpecificValidationConfig?.minLength ?? this.minLength;

    if (!minLength || !value) {
      return null;
    }

    const phoneNumberLength = this.preparePhoneNumberLengthForValidation(value);

    if (phoneNumberLength > 0 && phoneNumberLength < minLength) {
      return { minLength: { expectedMinLength: minLength } };
    }

    return null;
  }

  private isMaxLengthInvalid(value?: string) {
    const maxLength = this.countrySpecificValidationConfig?.maxLength ?? this.maxLength;

    if (!maxLength || !value) {
      return null;
    }

    const phoneNumberLength = this.preparePhoneNumberLengthForValidation(value);

    if (phoneNumberLength > maxLength) {
      return { maxLength: { expectedMaxLength: maxLength } };
    }

    return null;
  }

  private checkValidationPattern(control: AbstractControl) {
    if (this.validationPattern) {
      if (Validators.pattern(this.validationPattern)(control)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Resets `matchingCountry` if the `selectedCountry` was changed manually via the country dropdown
   */
  private resetMatchingCountry() {
    if (!this.enableCountryCode || !this.countryList) {
      return;
    }

    if (this.selectedCountryIsoCode !== this.matchingCountry?.isoCode) {
      this.matchingCountry = undefined;
    }
  }

  private updateResultCount(): void {
    this.resultCount = this.ngSelect?.itemsList.filteredItems.filter(({ children }) => !children).length || 0;
  }

  private constructCountryData(): void {
    if (!this.enableCountryCode || !this.countryList) {
      return;
    }

    const isoCode = this.selectedCountryIsoCode || this.defaultCountryIsoCode;
    const selectedCountry = this.countryList.find((country) => country.isoCode === isoCode) || this.countryList[0];

    this.countryDropdowndisabled = this.countryList.length === 1 || this.readonly || this.disabled;
    this.selectedCountryCode = selectedCountry.countryCode;
    this.selectedCountryName = selectedCountry.countryName;
    this.selectedCountryIsoCode = selectedCountry.isoCode;
    this.countrySpecificValidationConfig =
      this.countryCodeFormatConfiguration && this.countryCodeFormatConfiguration[this.selectedCountryCode];
  }

  private findMatchingCountryCodeFromValue(value = ''): void {
    if (!this.countryList) {
      return;
    }

    if (!this.enableCountryCode) {
      this.valueWithoutCountryCode = value;
      if (value.startsWith('+') && value.length >= 2) {
        const matchingCountry = extractCountryFromValue(value, this.countryList);

        this.valueWithoutCountryCode = stripCountryCodeFromValue(value, matchingCountry?.countryCode);
      }

      return;
    }

    if (value.startsWith('+') && value.length >= 2) {
      const matchingCountry = extractCountryFromValue(value, this.countryList);
      const { number, ...valueSet } = { ...this.valueSet };

      this.matchingCountry = matchingCountry?.countryCode === valueSet.countryCode ? valueSet : matchingCountry;

      if (this.matchingCountry) {
        this.noMatchingCountryFound = false;
        this.countrySpecificValidationConfig = this.countryCodeFormatConfiguration?.[this.matchingCountry.countryCode];
        this.valueWithoutCountryCode = stripCountryCodeFromValue(value, this.matchingCountry.countryCode);
      } else {
        this.noMatchingCountryFound = true;
        this.valueWithoutCountryCode = value;
      }
    } else {
      this.noMatchingCountryFound = false;
      this.valueWithoutCountryCode = value;
    }
  }

  private removeReadonly() {
    if (this.ngSelect && !this.readonly) {
      this.renderer2.removeAttribute(this.ngSelect?.searchInput.nativeElement, 'readonly');
    }
  }

  private preparePhoneNumberLengthForValidation(value: string) {
    if (this.enableCountryCode && value?.startsWith(this.valueSet.countryCode)) {
      // value contains both country code and phone number, we exclude country code for length validation
      return value.length - this.valueSet.countryCode.length;
    }

    // account for when users will call ".patch({ mobileNumber: x })" without the country code
    return value.length;
  }

  private moveAriaAttributes() {
    // HACK: ng-select listbox cannot have roles that are not "option"
    // We move listbox to be the direct parent of the list and not cover the searchbox

    const ngDropdownPanelElement = this.ngSelect?.element.querySelector('ng-dropdown-panel');
    const listOptionsElement = ngDropdownPanelElement?.querySelector('.ng-dropdown-panel-items');

    if (!ngDropdownPanelElement || !listOptionsElement) {
      return;
    }

    this.renderer2.setAttribute(listOptionsElement, 'role', 'listbox');
    this.renderer2.setAttribute(listOptionsElement, 'aria-label', 'List of countries');
    this.renderer2.removeAttribute(ngDropdownPanelElement, 'role');
  }

  private addAriaExpanded() {
    const comboboxElement = this.ngSelect?.element?.querySelector('[role="combobox"]');

    if (!comboboxElement || comboboxElement.hasAttribute('aria-expanded')) {
      return;
    }

    this.renderer2.setAttribute(comboboxElement, 'aria-expanded', 'false');
  }
}
