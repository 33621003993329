@if (displayOverlay) {
  <div class="bb-payment-card-state" [ngClass]="{ 'bb-payment-card-state--sm': size === PaymentCardSizes.SM }">
    <div class="bb-payment-card-state__container">
      @if (overlayIcon) {
        <div class="bb-block bb-block--none">
          <bb-icon-ui [name]="overlayIcon" size="xxl" color="light"></bb-icon-ui>
        </div>
      }
      @if (overlayText && size !== PaymentCardSizes.SM) {
        <bb-badge-ui [text]="overlayText" color="secondary"></bb-badge-ui>
      }
    </div>
    <div class="bb-payment-card-state__overlay">
      <ng-template [ngTemplateOutlet]="cardTemplate"></ng-template>
    </div>
  </div>
} @else {
  <ng-template [ngTemplateOutlet]="cardTemplate"></ng-template>
}

<ng-template #cardTemplate>
  <div
    class="bb-payment-card"
    [ngClass]="classNames"
    [ngStyle]="dynamicImageStyles"
    [class.bb-payment-card--sm]="size === PaymentCardSizes.SM"
  >
    <div class="bb-payment-card__container">
      @if (size !== PaymentCardSizes.SM) {
        @if (displayEmblem) {
          <div class="bb-payment-card__logo">
            <bb-logo-ui type="emblem" [isInverse]="true"></bb-logo-ui>
          </div>
        }
        @if (paymentCard?.vendor) {
          <div class="bb-payment-card__vendor">
            <div class="bb-block--xs">
              <bb-card-vendor-ui [vendor]="paymentCard?.vendor"></bb-card-vendor-ui>
            </div>
            @if (paymentCard?.type) {
              <div class="bb-payment-card__type">
                {{ paymentCard?.type }}
              </div>
            }
          </div>
        }
        @if (paymentCard?.number) {
          <div class="bb-payment-card__number">
            {{ paymentCard?.number | paymentCardNumber: paymentCardNumberFormat }}
          </div>
        }
        @if (paymentCard?.name) {
          <div class="bb-payment-card__name">
            {{ paymentCard?.name }}
          </div>
        }
        @if (paymentCard?.expirationDate) {
          <div class="bb-payment-card__expiration-date">
            <div
              class="bb-payment-card__expiration-date-label"
              i18n="Expiration date label@@paymentCard.expirationDateLabel"
            >
              Valid thru
            </div>
            <span class="bb-payment-card__expiration-date-date">
              {{ paymentCard?.expirationDate | date: 'MM/yy' }}
            </span>
          </div>
        }
        @if (paymentCard?.virtual) {
          <div class="bb-payment-card__virtual-tag" i18n="@@paymentCard.virtualTagLabel">Virtual</div>
        }
      }
    </div>
  </div>
</ng-template>
