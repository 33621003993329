@if (label) {
  <label for="{{ id }}" [attr.id]="labelId" (bbKeyboardClick)="onFocus()" class="form-label">{{ label }}</label>
}
<!-- isCLearVisible: true && squareBorder: false => --has-clear -->
<!-- isCLearVisible: true && squareBorder: true => '' -->
<!-- isCLearVisible: false && squareBorder: true => '' -->
<!-- isCLearVisible: false && squareBorder: false => '' -->
<div
  #searchBox
  [attr.role]="containerRole"
  [attr.aria-label]="containerAriaLabel"
  class="bb-search-box input-group"
  [ngClass]="[
    squareBorder ? 'squared' : '',
    showSearch ? 'bb-search-box--has-search' : '',
    showSearch || showClear ? 'bb-search-box-visible' : '',
    isClearVisible() ? 'bb-search-box--has-clear' : '',
    typeaheadOptions && typeaheadOptions.groupCssClasses ? typeaheadOptions.groupCssClasses : '',
    getKeywordBySize(size) ? 'bb-search-box--' + getKeywordBySize(size) : '',
    readonly ? 'bb-search-box--readonly' : ''
  ]"
>
  @if (showSearch) {
    <button
      bbButton
      [attr.data-id]="id ? id + '_search-box_search-button' : undefined"
      [attr.data-role]="'bb-search-button'"
      (click)="onSubmit()"
      color="tertiary"
      [buttonSize]="getKeywordBySize(size)"
      [attr.aria-label]="searchLabel"
      [disabled]="disabled"
      [ngClass]="[squareBorder ? 'rounded-outline' : '']"
    >
      <bb-icon-ui name="search" [size]="size"></bb-icon-ui>
    </button>
  }
  @if (typeaheadOptions) {
    <input
      #inputField
      type="search"
      inputmode="search"
      id="{{ id }}"
      class="bb-search-box__input form-control"
      aria-haspopup="listbox"
      [ngClass]="[size ? 'form-control-' + getKeywordBySize(size) : '']"
      [placeholder]="placeholder"
      [readOnly]="readonly"
      [disabled]="disabled"
      [attr.aria-disabled]="disabled"
      [attr.aria-autocomplete]="ariaAutocomplete"
      [attr.aria-controls]="ngbTypeahead?.popupId"
      [attr.aria-label]="ariaLabel"
      [attr.aria-describedby]="ariaDescribedby"
      [attr.aria-labelledby]="ariaLabelledby"
      [attr.aria-expanded]="ariaExpanded"
      [attr.role]="role || 'combobox'"
      [attr.aria-invalid]="ariaInvalid"
      [attr.aria-owns]="ariaOwns"
      [attr.aria-activedescendant]="ariaActivedescendant"
      [attr.maxlength]="maxLength"
      [ngModel]="value"
      (ngModelChange)="onValueChange($event)"
      [ngbTypeahead]="typeaheadOptions?.ngbTypeahead"
      [editable]="typeaheadOptions?.editable !== false"
      [focusFirst]="typeaheadOptions?.focusFirst !== false"
      [showHint]="typeaheadOptions?.showHint === true"
      [placement]="typeaheadOptions?.placement || (isRtl() ? 'bottom-right' : 'bottom-left')"
      [inputFormatter]="typeaheadOptions?.inputFormatter"
      [resultFormatter]="typeaheadOptions?.resultFormatter"
      [resultTemplate]="typeaheadOptions?.resultTemplate"
      [required]="required"
      [bbFocus]="autofocus"
      (selectItem)="onSelect($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keyup.enter)="onSubmit()"
      [attr.data-role]="'search-input'"
      [autocomplete]="autocomplete"
    />
  } @else {
    <input
      #inputField
      type="search"
      inputmode="search"
      [attr.id]="id"
      [attr.aria-autocomplete]="ariaAutocomplete"
      [attr.aria-label]="ariaLabel"
      [attr.aria-describedby]="ariaDescribedby"
      [attr.aria-labelledby]="ariaLabelledby"
      [attr.aria-expanded]="ariaExpanded"
      [attr.role]="role || 'searchbox'"
      [attr.aria-invalid]="ariaInvalid"
      [attr.aria-owns]="ariaOwns"
      [attr.aria-activedescendant]="ariaActivedescendant"
      [attr.aria-disabled]="disabled"
      class="bb-search-box__simple-input form-control"
      [placeholder]="placeholder"
      [attr.maxlength]="maxLength"
      [ngModel]="value"
      [readOnly]="readonly"
      [required]="required"
      [disabled]="disabled"
      [bbFocus]="autofocus"
      (ngModelChange)="onValueChange($event)"
      (keyup.enter)="onSubmit()"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [attr.data-role]="'search-input'"
      [ngClass]="[size ? 'form-control-' + getKeywordBySize(size) : '']"
      [autocomplete]="autocomplete"
    />
  }
  @if (isClearVisible()) {
    <button
      bbButton
      [attr.data-id]="id ? id + '_search-box_clear-button' : undefined"
      [attr.data-role]="'bb-clear-button'"
      (click)="onClear()"
      color="tertiary"
      [attr.aria-label]="clearLabel"
      [buttonSize]="getKeywordBySize(size)"
      [disabled]="disabled"
      [ngClass]="[squareBorder ? 'rounded-outline' : '']"
    >
      <bb-icon-ui name="cancel" size="sm"></bb-icon-ui>
    </button>
  }
  <ng-content></ng-content>
</div>
